.graphiql-container .doc-explorer-title,
.graphiql-container .history-title {
  overflow-x: visible;
}

.graphiql-container .doc-explorer-title-bar,
.graphiql-container .history-title-bar {
  height: 52px;
}

.graphiql-container .doc-explorer-contents,
.graphiql-container .history-contents {
  top: 51px;
}

.graphiql-container .doc-explorer-back {
  padding: 0 0 0 16px;
  display: flex;
  align-items: center;
}
